/*
 Common JS that will be loaded on every page
 IMPORTANT: webpackChunkNames should be unique
 */

import {confettiAnimation, confettiClickAnimation} from "./modules/confettiClickAnimation";

const global = async () => {
    const LazySizes = await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes'
        );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/respimg/ls.respimg.js'
        );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/parent-fit/ls.parent-fit.js'
        );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/object-fit/ls.object-fit.js'
        );

    // fix issue when image is already in viewport and content is not loaded yet
    LazySizes.cfg.init = false;
    LazySizes.init();

    // bexio Project
    if (document.getElementsByClassName('theme-bexio').length || document.getElementsByClassName('theme-community-show').length) {
        await import(/* webpackChunkName: "wizardCountryHandler" */ '../../bexio/js/magics/wizardCountryHandler');

        // accountantDirectory
        if (document.getElementById('accountant-directory')) {
            await import(/* webpackChunkName: "accountantDirectory" */ './../../bexio/js/alpine/accountantDirectory');
            await import(/* webpackChunkName: "lightswitchChanged" */ '../../global/js/magics/lightswitchChanged');
            await import(/* webpackChunkName: 'markerClusterer' */ '@googlemaps/markerclusterer').then(
                module => {
                    window.googleMapsClusterer = module;
                }
            );
        }

        // Setup Service
        if (document.getElementsByClassName('setupServiceComponent').length) {
            await import(/* webpackChunkName: "setupService" */ './../../global/js/alpine/setupService');
        }

        // mobiliarFormComponent
        if (document.getElementById('mobiliarFormComponent')) {
            await import(/* webpackChunkName: "formMobiliar" */ './../../bexio/js/alpine/formMobiliar');
        }
    }

    // Accountant Project
    if (document.getElementsByClassName('theme-accountant').length) {
        // Global Scope
        await import(/* webpackChunkName: "app" */ './../../accountant/js/alpine/accountantApp');
    }

    if (document.getElementsByClassName('theme-blog-2').length || document.getElementsByClassName('theme-bexio').length) {
        // wizardComponent
        await import(/* webpackChunkName: "wizardComponent" */ './alpine/wizard');
        // wizard click function on theme element
        await import(/* webpackChunkName: "wizardApp" */ './alpine/wizardApp.js');

    }

    if (document.getElementsByClassName('theme-blog-2').length) {
        if (document.getElementsByClassName('progress-bar').length) {
            await import(/* webpackChunkName: "progressBar" */ './../../blog/js/alpine/progressBar.js');
        }

        if (document.getElementsByClassName('scroll-to-top').length) {
            await import(/* webpackChunkName: "scrollToTop" */ './../../global/js/magics/scrollToTop.js');
        }

        if (document.getElementsByClassName('article-before-after').length) {
            await import(/* webpackChunkName: "articleBeforeAfter" */ './../../blog/js/alpine/articleBeforeAfter');
        }

        if (document.getElementById('table-of-content')) {
            await import(/* webpackChunkName: "tableOfContent" */ './../../blog/js/alpine/tableOfContent.js');
        }

        if (document.getElementById('toc-button')) {
            await import(/* webpackChunkName: "tocButton" */ './../../blog/js/alpine/tableOfContentButton.js');
        }

        if (document.getElementById('share')) {
            await import(/* webpackChunkName: "share" */ './../../blog/js/alpine/share.js');
        }

        if (document.getElementsByClassName('blog-articles').length) {
            await import(/* webpackChunkName: "blogArticles" */ './../../global/js/alpine/blogArticles.js');
        }

        let articleSlider = document.getElementById('article-slider');
        if (articleSlider) {
            await import(/* webpackChunkName: "articleSlider" */ '../../global/js/modules/articleSlider').then(
                module => {
                    module.default.init(articleSlider);
                });
        }
        // Blog Category Slider
        const blogSlider = document.getElementById('blog-category-slider');
        if (blogSlider) {
            await import(/* webpackChunkName: "blogSlider" */ '../../global/js/modules/blogSlider').then(
                module => {
                    module.default.init(blogSlider);
                });
        }

        // Blog Author Expert Slider
        const blogAuthorExpertSlider = document.getElementById('blog-author-expert-slider');
        if (blogAuthorExpertSlider) {
            await import(/* webpackChunkName: "blogAuthorExpertSlider" */ '../../global/js/modules/blogAuthorExpertSlider').then(
                module => {
                    module.default.init(blogAuthorExpertSlider);
                });
        }
    }
    // Event registration
    if (document.getElementsByClassName('eventRegistration').length) {
        await import(/* webpackChunkName: "eventRegistration" */ '../../global/js/alpine/eventRegistration');
        await import(/* webpackChunkName: "userStore" */ './../../global/js/alpine/userStore');

        // translator
        await import(/* webpackChunkName: "requests" */ '../../global/js/modules/translator').then((module) => {
            window.translator = module.default;
            window.translator.init();
        });
    }

    // Event detail
    if (document.getElementsByClassName('event-detail').length) {
        await import(/* webpackChunkName: "eventDetail" */ '../../global/js/alpine/eventDetail');
    }

    // Navigation
    await import(/* webpackChunkName: "navi" */ '../../global/js/alpine/navigation');

    // Multi Dropdown field
    if (document.getElementsByClassName('multiDropdownFilter').length) {
        await import(/* webpackChunkName: "multiDropdownFilter" */ '../../global/js/alpine/multiDropdownFilter');
    }

    // Marketplace Overview
    if (document.getElementsByClassName('marketplace-overview').length) {
        await import(/* webpackChunkName: "marketplaceOverview" */ '../../global/js/alpine/marketplaceOverview');
    }

    // Google Review Slider
    const googleReviewSlider = document.getElementById('google-review-slider');
    if (googleReviewSlider) {
        await import(/* webpackChunkName: "googleReviewSlider" */ '../../global/js/modules/googleReviewSlider').then(
            module => {
                module.default.init(googleReviewSlider);
            });
    }

    // Marketplace Slider
    const imageSlider = document.getElementsByClassName('image-slider');
    if (imageSlider.length) {
        await import(/* webpackChunkName: "imageSlider" */ '../../global/js/modules/imageSlider').then(
            module => {
                module.default.init(imageSlider);
            });
    }

    // P&P Slider
    const packagesPricesSlider = document.getElementsByClassName('packages-prices-slider');
    if (packagesPricesSlider.length) {
        await import(/* webpackChunkName: "packagesPricesSlider" */ '../../global/js/modules/packagesPricesSlider').then(
            module => {
                module.default.init(packagesPricesSlider);
            });
    }

    // FAQ or Compare-Table component
    if (document.getElementsByClassName('accordion-item').length) {
        await import(/* webpackChunkName: "accordion" */ '../../global/js/alpine/accordionItem');
    }

    // Dropdown (select field)
    if (document.getElementsByClassName('dropdown-input').length) {
        await import(/* webpackChunkName: "dropdownInput" */ '../../global/js/alpine/dropdownInput');
    }

    // Dropdown (select field)
    if (document.getElementsByClassName('dropdown-master').length) {
        await import(/* webpackChunkName: "dropdownMaster" */ '../../global/js/alpine/dropdownMaster');
    }

    // textarea
    if (document.getElementsByClassName('textarea-field').length) {
        await import(/* webpackChunkName: "textarea" */ '../../global/js/alpine/textarea');
    }

    // Dropdown for Phone-Prefix
    if (document.getElementsByClassName('dropdown-phone-prefix').length) {
        await import(/* webpackChunkName: "dropdownPhonePrefix" */ '../../global/js/alpine/dropdownPhonePrefix');
    }

    // Input & Input-Master field
    if (document.getElementsByClassName('input-field').length || document.getElementsByClassName('input-master').length) {
        await import(/* webpackChunkName: "input" */ '../../global/js/alpine/input');
    }

    // Lightswitch
    if (document.getElementsByClassName('lightswitch').length) {
        await import(/* webpackChunkName: "lightswitch" */ '../../global/js/alpine/lightswitch');
    }

    // Modal
    if (document.getElementsByClassName('modal').length) {
        await import(/* webpackChunkName: "modal" */ '../../global/js/alpine/modal');
    }

    // Modal Messages
    if (document.getElementsByClassName('modal-message')) {
        await import(/* webpackChunkName: "modalMessage" */ '../../global/js/alpine/modalMessage');
    }

    let bxValidate = document.getElementsByClassName('bx-validate');
    if (bxValidate.length) {
        // bxValidation
        await import(/* webpackChunkName: "bxValidate" */ './modules/bxValidate').then(
            module => {
                window.bxValidate = module.default();
            },
        );
    }

    // Form
    if (document.getElementsByClassName('alpineForm').length) {
        await import(/* webpackChunkName: "form" */ './../../global/js/alpine/form');
        await import(/* webpackChunkName: "localStorage" */ './../../global/js/magics/localStorage');
        /*await import(/!* webpackChunkName: "multiStepFormNextStep" *!/ '../../global/js/magics/multiStepForm');
        await import(/!* webpackChunkName: "fieldsets" *!/ '../../global/js/alpine/fieldsets');*/
    }

    // Advertising Lightbox
    if(document.getElementsByClassName('advertising-lightbox-promotion').length) {
        await import(/* webpackChunkName: "promotionLightbox" */ './../../global/js/alpine/promotionLightbox');
    }

    // Request functions
    await import(/* webpackChunkName: "requests" */ '../../global/js/modules/requests').then((module) => {
        window.getRequest = module.default.getRequest;
        window.postRequest = module.default.postRequest;
    });


    // Request functions
    await import(/* webpackChunkName: "requests" */ '../../global/js/modules/pageChangeEvents').then((module) => {
        window.onPageHide = module.default.onPageHide;
        window.onPageShow = module.default.onPageShow;
    });

    // await import domUtils
    await import(/* webpackChunkName: "domUtils" */ '../../global/js/modules/domUtils').then((module) => {
        window.domUtils = module.default;
    });

    // Global Store
    await import(/* webpackChunkName: "globalStore" */ '../../global/js/alpine/globalStore');

    // Helpers
    await import(/* webpackChunkName: "helpers" */ '../../global/js/modules/helpers').then((module) => {
        window.helpers = module.default;
    });

    // validate paying customer cookie
    if (document.getElementsByClassName('advertising-promotion').length || document.getElementsByClassName('advertising-lightbox-promotion').length){
        await import(/* webpackChunkName: "validatePayingCustomerCookie" */ './modules/validatePayingCustomerCookie.js');
    }

    // Content Slider (Splide)
    let contentSlider = document.getElementsByClassName('content-slider');
    if (contentSlider.length) {
        await import(/* webpackChunkName: "componentSlider" */ '../../global/js/modules/contentSlider').then(
                module => {
                    module.default.init(contentSlider);
                });
    }

    // Library
    if (document.getElementsByClassName('libraryComponent').length) {
        await import(/* webpackChunkName: "library" */ './../../global/js/alpine/library');
    }

    // Events Overview
    if (document.getElementsByClassName('cmp-event-overview').length) {
        await import(/* webpackChunkName: "library" */ './../../global/js/alpine/events');
    }

    // bexio user authentication
    if (document.getElementsByClassName('user-authentication').length) {
        await import(/* webpackChunkName: "bxUserAuthentication" */ './../../global/js/alpine/bxUserAuthentication');
    }

    if (document.getElementsByClassName('sort-dropdown').length) {
        await import(/* webpackChunkName: "sortDropdown" */ './alpine/sortDropdown');
    }
    if (document.querySelector("[\\@dispatchnewvalueevent]")) {
        await import(/* webpackChunkName: "dispatchNewValueEvent" */ '../../global/js/magics/dispatchNewValueEvent');
    }

    if (document.getElementsByClassName("card-clickable")) {
        await import(/* webpackChunkName: "dispatchNewValueEvent" */ '../../global/js/magics/clickChildLink');
    }
    if (document.getElementsByClassName('price-switcher')) {
        await import(/* webpackChunkName: "dispatchNewValueEvent" */ '../../global/js/alpine/priceSwitcherStore');
    }

    if(document.getElementById('confetti')) {
        await import(/* webpackChunkName: "confettiClickAnimation" */ './modules/confettiClickAnimation').then(
                module => {
                    module.confettiClickAnimation.init();
                });
    }

    if (document.getElementsByClassName('countdown').length) {
        await import(/* webpackChunkName: "countdown" */ '../../global/js/alpine/countdown');
    }

    if (document.getElementsByClassName('action-item').length || document.getElementsByClassName('dropdown-master-menu').length) {
        await import(/* webpackChunkName: "formActionItems" */ '../../global/js/magics/formActionItems.js');
    }

    // paid content - vimeo
    if (document.getElementsByClassName('paid-content').length) {
        await import(/* webpackChunkName: "paidContent" */ './alpine/paidContent');
    }

    if (document.getElementsByClassName('glightbox').length) {
        import(/* webpackChunkName: "gLightBox" */ './../../global/js/modules/glightbox');
    }

    // not used yet
    //await import(/* webpackChunkName: "toggleElement" */ '../../global/js/magics/toggleElement');
};

global().then(() => {
    // Load Alpine library
    import(/* webpackChunkName: "alpineJs" */ 'alpinejs').then(
        module => {
            window.Alpine = module.default;
            module.default.start();
        }
    );

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
