/*
 Common JS that will be loaded on bexio pages
 */
if (document.getElementsByClassName('theme-bexio').length) {
    // language switcher
    if (document.getElementsByClassName('footer-language').length) {
        import(/* webpackChunkName: "languageSwitcher" */'./modules/language_switcher.js');
    }

    // faq
    if (document.getElementsByClassName('faq-item').length) {
        import(/* webpackChunkName: "faq" */ './../../global/js/modules/faq');
    }

    // partner carousel
    if (document.getElementsByClassName('js-partnerCarousel').length) {
        const partnerItems = document.getElementsByClassName('js-partnerItems').length;
        import(/* webpackChunkName: "glide" */'../../accountant/js/modules/glide.js')
            .then(glide => glide.carousel.init('js-partnerCarousel', partnerItems))
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    // Salary calculator
    if(document.getElementsByClassName('salary-calculator').length) {
        import(/* webpackChunkName: "salaryCalculatorMagic" */'./magics/salaryCalculatorMagic');
    }
}

// Recommendation form
if(document.getElementsByClassName('bexio-recommendation-invite').length) {
    import(/* webpackChunkName: "recommendation" */'./alpine/recommendation');
}

// Recommendation form
if(document.getElementsByClassName('bexio-recommendation-invite').length) {
    import(/* webpackChunkName: "recommendation" */'./alpine/recommendation');
}
